@import './fonts.css';
@import './landing-page.css';

@font-face {
  font-family: 'Selawik';
  src: url('/fonts/selawik/selawik.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
  font-family: 'Hind';
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer base {
  * {
    @apply text-primary;
  }

  .container {
    @media (min-width: 1800px) {
      max-width: 1640px !important;
    }
  }

  p {
    @apply text-base sm:text-lg md:text-xl lg:text-[17px] xl:text-[20px] leading-normal lg:leading-7
  }

  h1 {
    @apply font-selawik font-medium pb-8 text-4xl sm:text-5xl lg:text-6xl xl:text-7xl leading-tight sm:leading-tight md:leading-tight lg:leading-tight xl:leading-tight;
  }

  h2 {
    @apply font-selawik font-medium pb-6 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-normal sm:leading-normal md:leading-normal lg:leading-normal xl:leading-[1.3];
  }

  h2.rh-lg, .rh-lg {
    @apply font-selawik font-medium pb-2 sm:pb-8 text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl leading-tight sm:leading-tight md:leading-tight lg:leading-tight xl:leading-tight;
  }

  h3 {
    @apply font-selawik font-medium pb-4 text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-normal sm:leading-normal md:leading-normal lg:leading-normal xl:leading-[1.3];
  }

  h4 {
    @apply text-lg sm:text-xl md:text-2xl lg:text-[25px] xl:text-[30px] font-normal pb-2 leading-tight;
  }

  .main {
    ul {
      @apply list-disc list-inside;
    }
  }

  .rh-btn-login {
    @apply text-2xl text-primary px-6 pb-2 pt-3 font-semibold transition duration-300;
    /* @apply text-2xl bg-primary !text-white px-6 pb-2 pt-3 rounded-[50px] font-semibold hover:bg-gray-900 transition duration-300; */
  }

  /* .rh-btn-login {
      @apply bg-primary text-white px-6 py-2 rounded-full hover:bg-primary/90 transition-colors;
    } */

  /* Add smooth transitions for menu */
  .navbar-menu-transition {
    @apply transition-all duration-300 ease-in-out;
  }

  .rh-btn-black-lg {
    @apply text-xl lg:text-2xl xl:text-[24px] px-6 md:px-7 lg:px-8 pt-2.5 pb-2 sm:py-3 lg:py-4 rounded-[50px] font-semibold bg-primary text-white hover:bg-gray-900 transition duration-300;
  }

  .rh-btn-black {
    @apply text-base sm:text-lg lg:text-xl px-5 sm:px-6 lg:px-7 py-2 sm:py-2.5 lg:py-3 rounded-[50px] font-semibold bg-primary text-white hover:bg-gray-900 transition duration-300;
  }

  @media (max-width: 768px) {
    .rh-btn-black {
      padding-top: 0.75rem;
    }
  }

  .rh-page-hero {
    background-image: url('/images/hero-gradient.jpg');
    background-position: 10% 20%;
  }

  .breadcrumbs {
    @apply mb-4;

    a {
      @apply mr-2;
    }

    a:not(:first-child) {
      @apply ml-2;
    }
  }

  .program-details {
    ul {
      @apply list-disc list-outside ml-6;
    }
  }

  .sitemap {
    ul {
      @apply list-none;

      li {
        @apply mb-2 text-nowrap;
      }

      ul {
        @apply list-disc list-inside mt-2;

        ul {
          @apply ml-6;
        }
      }
    }
  }

}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}